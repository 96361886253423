<template>
	<div class="publicEmergencies">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="事件名称：">
					<el-autocomplete
						v-model.trim="formInline.eventName"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
					></el-autocomplete>
				</el-form-item>
				<!-- <el-form-item label="报告类别：">
					<el-select v-model="formInline.reportTypeDictionaryItemId" placeholder="请选择">
						<el-option v-for="(item, index) in portList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'healthyText',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				eventName: '',
				reportTypeDictionaryItemId: null,
			},
			portList: [],
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '事件名称',
					prop: 'eventName',
					width: '150',
					formatter: (row) => {
						return row.eventName || '-';
					},
				},
				{
					label: '报告类别',
					width: '150',
					prop: 'reportType',
					formatter: (row) => {
						return row.reportType || '-';
					},
				},
				{
					label: '突发事件等级',
					prop: 'eventLevel',
					width: '150',
					formatter: (row) => {
						return row.eventLevel || '-';
					},
				},
				{
					label: '发生地区',
					width: '220',
					prop: 'reportAreaRegionName',
					formatter: (row) => {
						return row.reportAreaRegionName || '-';
					},
				},
				{
					label: '发生场所',
					prop: 'address',
					width: '150',
					formatter: (row) => {
						return row.address || '-';
					},
				},
				{
					label: '新报告病例数',
					prop: 'newlyReportedCases',
					width: '150',
					formatter: (row) => {
						return row.newlyReportedCases || '-';
					},
				},
				{
					label: '报告人',
					prop: 'speaker',
					width: '150',
					formatter: (row) => {
						return row.speaker || '-';
					},
				},
				{
					label: '报告人联系方式',
					prop: 'phoneNumber',
					width: '150',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '报告时间',
					prop: 'reportTime',
					width: 160,
					formatter: (row) => {
						return row.reportTime || '-';
					},
				},
				{
					label: '提交人',
					prop: 'creator',
					width: '150',
					formatter: (row) => {
						return row.creator || '-';
					},
				},
				{
					label: '提交人联系电话',
					prop: 'creatorphoneNumber',
					width: '150',
					formatter: (row) => {
						return row.creatorphoneNumber || '-';
					},
				},
				{
					label: '提交时间',
					prop: 'creatTime',
					width: 160,
					formatter: (row) => {
						return row.creatTime || '-';
					},
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getPortList();
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		// 获取报告下拉
		getPortList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'EVENT_REPORT_TYPE', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.portList = res.data.collection || [];
						this.portList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let par = {
				contentCode: 'PEMI',
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: par })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || {};
						this.loading = false;
						if (collection.length == 0) {
							this.tableData = [];
							this.total = 0;
							return;
						}
						collection = collection.map((item) => {
							return {
								...item,
								content: item.content ? JSON.parse(item.content) : {},
							};
						});
						let arr = collection.map((item) => {
							if (JSON.stringify(item.content) !== '{}') {
								return {
									id: item.id,
									creator: item.creator?.title || '-',
									eventName: item.content.items[3].items[0].value?.toString() || '-',
									reportType:
										item.content.items[4].items[0].value
											?.map((v) => {
												return v.match(/\d+[,，.、]?\s*(.*)/)[1];
											})
											.toString() || '-',
									eventLevel:
										item.content.items[5].items[0].value
											?.map((v) => {
												return v.match(/\d+[,，.、]?\s*(.*)/)[1];
											})
											.toString() || '-',
									reportAreaRegionName: item.content.items[13].items.map((v) => v.value).join('') || '-',
									address:
										item.content.items[15].items[0].value
											?.map((v) => {
												return v.match(/\d+[,，.、]?\s*(.*)/)[1];
											})
											.toString() || '-',
									newlyReportedCases: item.content.items[19].items[0].value?.toString() || '-',
									speaker: item.content.items[1].items[0].value?.toString() || '-',
									phoneNumber: item.content.items[2].items[0].value?.toString() || '-',
									creatTime: item.content.items[0].items[0].value?.toString() || '-',
									reportTime: item.content.items[25].items[0].value?.toString() || '-',
									creatorphoneNumber: item.creator?.code || '-',
								};
							}
						});

						if (type == 'filter') {
							this.filterLoading = false;
							this.options = arr.map((item) => {
								return {
									...item,
									value: item.eventName,
								};
							});
						} else {
							this.tableData = JSON.parse(JSON.stringify(arr)) || [];
							this.total = res.data.pagination.totalItems || 0;
						}
					}
				})
				.catch((e) => {});
		},
		modifyParkInfo(row) {
			this.$router.push({
				path: '/publicHealth/publicEmergencies/details',
				query: {
					id: row.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>